/** @jsx jsx */
import { graphql } from "gatsby";
import React, { memo } from "react";
import ParsedHTML from "shared/components/ParsedHTML";
import { jsx } from "theme-ui";

import type { PageQuery } from "../../../../../__generated__/gatsby.types";
import WidgetWrapper from "../../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetEventLongDescription" }>;
  data: PageQuery["event"];
}

const EventLongDescription: React.FC<Props> = ({
  data,
  isFirst,
  isLast,
  isNested,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  if (!data?.description) {
    return null;
  }

  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <ParsedHTML>{data.description || ""}</ParsedHTML>
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetEventLongDescription on WidgetEventLongDescription {
    id
    __typename
  }
`;

export default memo(EventLongDescription);
